// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-components-pages-404-tsx": () => import("./../src/components/pages/404.tsx" /* webpackChunkName: "component---src-components-pages-404-tsx" */),
  "component---src-components-pages-imprint-tsx": () => import("./../src/components/pages/imprint.tsx" /* webpackChunkName: "component---src-components-pages-imprint-tsx" */),
  "component---src-components-pages-index-tsx": () => import("./../src/components/pages/index.tsx" /* webpackChunkName: "component---src-components-pages-index-tsx" */),
  "component---src-components-pages-privacy-tsx": () => import("./../src/components/pages/privacy.tsx" /* webpackChunkName: "component---src-components-pages-privacy-tsx" */)
}

